import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Arrow from 'react-arrow'

const grading = ({data}) => (
<Layout>
    <SEO title="Grading at Scale" />
    <div className="Hero">
      <div className="HeroImage">
        <Img fluid={data.gradingHero.childImageSharp.fluid} alt="Grading Hero Image" />
      </div>
    </div>

    <div className="PageContent">
        <h1>Grading at scale</h1>
        <h4>Time: Summer 2021 - present</h4>

        <h2>About</h2>
        <p>Coursera has the vision to become the best platform for online degrees, and grading is a critical part. Our grading vision is <strong>to become the best platform for grading large-scale online degrees</strong>. Since summer 2021, I've partnered with the product manager, researcher, and other stakeholders to shape grading strategies and develop concepts. In the process, I </p>
        <ul className="list">
                        <li>co-led cross-functional workshops using our product-market-fit framework.</li>
                        <li>created a journey map of primary personas in the grading process.</li>
                        <li>benchmarked with competitive grading tools.</li>
                        <li>identified key product areas for improvement and designed concepts. </li>
                        <li>co-presented strategy to senior product leadership.</li>
                    </ul>


<p>Currently, we are designing and building the solution to make grading more efficient. In the meantime, we are concept testing how to manage grading more efficiently.</p>  

<p>Please <a href="mailto: qianmanqian@gmail.com">contact me</a> to learn more about my design. :)</p> 
        
        
       
       
    </div>

    <div className="BottomNav">
      <div className="leftNav" >
      
      </div>
      <div className="rightNav">
        <Link to="/autoteams">Next project<Arrow className="arrow"
          direction="right"
          shaftWidth={2}
          shaftLength={8}
          headWidth={10}
          headLength={8}
          />
        </Link>
        
      </div>
      
    </div>

</Layout>

)

export default grading

export const query = graphql`
    query {
        gradingHero: file(relativePath: {eq: "grading-hero.png"}) {
            childImageSharp {
            fluid(maxWidth: 1112) {
            ...GatsbyImageSharpFluid
            }
            }
        }
        

}

`